  body {
      &.noscroll {
        overflow: hidden;
        @media only screen and (max-width: 767px) {
            position: fixed;
        }
      }
  }
  .testttttt {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 3000000000;
  }

:root {
    --main-color: #008b40;
    --darkk-color: #50757c;
    --dark-color: #f08c02;
    --hover-color: #176334;
}
@font-face {
    font-family: 'SF bold';
    font-style: normal;
    src: url('assets/fonts/SF-UI-Display-Heavy_0.otf');
}
@font-face {
    font-family: 'SF semibold';
    font-style: normal;
    src: url('assets/fonts/SF-UI-Display-Semibold_0.otf');
}
@font-face {
    font-family: 'SF text';
    font-style: normal;
    src: url('assets/fonts/SF-Pro-Text-Regular.otf');
}
@font-face {
    font-family: 'SF UI Medium';
    font-style: normal;
    src: url('assets/fonts/SF-UI-Display-Medium_0.otf');
}
ul {
    margin: 0;
    padding: 0;
}
.moja-container {
    padding: 0 30px;
}
.mojabet-website {
    background-color: #f0f0f0;
}
.moja-footer .moja-container {
    width: 90%;
    margin: auto;
}
.only-mobile {
    display: none !important;
}
.main-btn {
    background: #ffffff;
    color: var(--main-color);
    border-radius: 10px;
    padding: 0 20px;
    height: 35px;
    line-height: 37px;
    font-family: "SF text";
    text-align: center;
    outline: none;
    border: none;
    font-size: 14px;
    text-transform: uppercase;
}
.main-btn:hover,
.main-btn:focus {
    background: var(--main-color);
    color: #ffffff;
    text-decoration: none;
}
.react-numeric-input {
    margin: 20px 0;
}
.react-numeric-input input  {
    background: #ffffff;
    height: 60px;
    line-height: 60px;
    color: var(--dark-color);
    font-size: 30px !important;
    text-align: center;
    width: 100px;
    margin: auto;
    padding: 0 10px !important;
    font-family: "SF bold";
    border-radius: 15px;
}
.react-numeric-input b {
    background: var(--main-color) !important;
    width: 40px !important;
    height: 40px !important;
    border-radius: 10px !important;
    position: absolute;
    border: none !important;
    top: 50% !important;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
}
.react-numeric-input b:first-of-type{
    right:  0 !important;
}
.react-numeric-input b:after {
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%,-50%);
    -ms-transform: translate(-50%, -50%);
    color: #ffffff;
    font-size: 35px;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    position: absolute;
}
.react-numeric-input b:first-of-type:after {
    content: '\f067';
}
.react-numeric-input b:last-of-type:after {
    content: '\f068';
}
.react-numeric-input b:last-of-type {
    left:  0 !important;
}
.react-numeric-input b i {
    border: none !important;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

 input[type=number] {
    -moz-appearance:textfield;
}
.loading-container .fa {
    color: var(--main-color);
}
.success .fa,
.success span {
    color: var(--main-color);
}
.error span,
.success span,
.warning span {
    display: inline-block;
    margin-right: 0;
    vertical-align: middle;
}
.error .fa,
.success .fa,
.warning .fa {
    display: inline-block;
    margin-right: 5px;
    vertical-align: middle;
    font-size: 18px;
}
.container-fluid.content-container {
    width: 80%;
}
.staticpages-content .page-title-container {
    margin-bottom: 40px;
}
.staticpages-content .page-title {
    color: #fff;
    text-transform: uppercase;
    font-size: 40px;
    font-family: SF bold;
    line-height: 40px;
    text-align: center;
}
.form-registration {
    background: var(--dark-color);
    padding-bottom: 20px;
}
.form-registration .form-title {
    background: var(--main-color);
    height: 50px;
    line-height: 50px;
    font-family: SF semibold;
    text-transform: uppercase;
    color: #fff;
    font-size: 25px;
    width: 100%;
    padding: 0 60px;
}
.form-registration .form-container {
    padding: 30px 60px;
}
.form-registration form>div:nth-child(odd) {
    margin-right: 5%;
}
.form-registration form>div {
    width: 47%;
    margin-bottom: 20px;
    display: inline-block;
    position: relative;
}
.form-registration label {
    font-size: 18px;
    display: inline-block;
    vertical-align: top;
    color: #fff;
    text-align: left;
    width: 45%;
    font-family: "SF semibold";
}
.form-registration .input-container {
    width: 55%;
    display: inline-block;
}
.form-registration .inputfield {
    border: 1px solid #fff;
    background: none;
    border-radius: 5px;
    width: 100%;
    padding: 0 20px;
    height: 45px;
    line-height: 45px;
    color: #fff;
    font-family: "SF text";
    font-size: 14px;
}
.form-registration .submit-btn {
    background: #d5e6f1;
    border: 2px solid #fff;
    margin: 20px auto 0;
    color: var(--dark-color);
    height: 40px;
    line-height: 35px;
    font-family: "SF bold";
    text-transform: uppercase;
    font-size: 20px;
    border-radius: 5px;
    display: block;
    padding: 0 5px;
}
.form-registration form>div.terms {
    width: 100%;
}
.form-registration .terms label {
    width: auto;
    margin-right: 15px;
}
.form-registration form>div.terms .input-container {
    width: auto;
}
.staticpages-content {
    padding: 50px 0 100px;
}
.staticpages-content .page-title-container {
    margin-bottom: 40px;
}
.staticpages-content .page-title {
    color: var(--main-color);
    text-transform: uppercase;
    font-size: 40px;
    font-family: "SF bold";
    line-height: 40px;
    text-align: center;
}
.mobile-field .mobile-code {
    width: 60px;
    margin-right: 10px;
    display: inline-block;
    border: 1px solid #fff;
    background: none;
    border-radius: 5px;
    padding: 0 10px;
    height: 45px;
    line-height: 45px;
    color: #fff;
    font-family: SF text;
    font-size: 14px;
    margin-bottom: 0;
}
.mobile-field .inputfield {
    width: calc(100% - 70px);
}

.account-basics {
    text-align: center;
}
.account-basics > div {
    display: flex;
    justify-content: space-between;
    text-align: center;
    width: 100%;
    margin-top: 30px;
}
.account-basics h2 {
    font-family: "SF UI Medium";
    font-size: 25px;
    color: var(--main-color);
    margin-bottom: 0;
}
.account-basics h3 {
    font-family: "SF bold";
    font-size: 20px;
    color: var(--dark-color);
    margin-bottom: 0;
}
.account-basics .account-email {
    display: block;
    font-family: "SF UI Regular";
    font-size: 16px;
    color: #ffffff;
    margin-bottom: 20px;
}
.account-basics .main-btn {
    display: inline-block;
    width: auto;
    padding: 0 15px;
    margin: auto;
    margin-bottom: 10px;
    height: 40px;
    line-height: 39px;
    background: #ffffff;
    color: var(--dark-color);
    border-color: #ffffff;
}

.account-basics .main-btn .fa {
    font-size: 15px;
    display: inline;
    margin-right: 10px;
}
.message-action {
    text-align: center;
    color: #ffffff;
    background: var(--dark-color);
    font-family: "SF UI Light";
    font-size: 16px;
    width: 50%;
    padding: 5px 0;
    margin: 10px auto;

}
.container-fluid.account-container {
    width: 90%;
}
.account-container .tab-content>.tab-pane {
    opacity: 1;
}
.tabs-container .nav-tabs {
    margin-bottom: 50px;
    padding: 0;
}
.tabs-container .nav-tabs,
.account-container .nav-tabs {
    display: table;
    table-layout: fixed;
    width: 100%;
    text-align: center;
    margin-top: 30px;
}
.tabs-container .nav-tabs li,
.account-container .nav-tabs li {
    display: table-cell;
    border-radius: 5px 5px 0 0;
    padding: 10px 30px;
}
.tabs-container .nav-tabs li {
    background: var(--dark-color);
}
.account-container .nav-tabs li {
    background-color: #fff;
}
.tabs-container .nav-tabs li a,
.account-container .nav-tabs li a {
    font-family: "SF semibold";
    font-size: 17px;
    text-transform: uppercase;
    display: block;
    width: 100%;
    height: 100%;
}
.tabs-container .nav-tabs li a {
    color: #ffffff;
}
.account-container .nav-tabs li a {
    color: var(--dark-color);
}
.tabs-container .nav-tabs li a:hover,
.account-container .nav-tabs li a:hover {
    text-decoration: none;
}
.tabs-container .nav-tabs li.active,
.tabs-container .nav-tabs li:hover {
    background: #ffffff;
}
.account-container .nav-tabs li.active,
.account-container .nav-tabs li:hover {
    background: var(--dark-color);
}
.tabs-container .nav-tabs li.active a,
.tabs-container .nav-tabs li:hover a {
    color: var(--dark-color)
}
.account-container .nav-tabs li.active a,
.account-container .nav-tabs li:hover a {
    color: #ffffff;
}
.forgot-tab {
    display: none;
}
.forgot-tab.active {
    display: block;
}
.account-container .tab-pane {
    -webkit-box-shadow: 0px 4px 20px 0px rgba(0,0,0,0.43);
    -moz-box-shadow: 0px 4px 20px 0px rgba(0,0,0,0.43);
    box-shadow: 0px 4px 20px 0px rgba(0,0,0,0.43);
    padding: 70px;
    margin-top: -1px;
}
.account-container .tab-pane.profile-tab,
.account-container .tab-pane.transactions-tab,
.account-container .tab-pane.tickets-tab,
.account-container .tab-pane.banks-tab,
.account-container .tab-pane.promo-tab {
    background-color: var(--main-color);
}
.account-container .tab-pane.banks-tab {
    padding: 0 0 30px;
}
.account-info {
    width: 80%;
    margin: auto;
    overflow: hidden;
}
.account-info .half-column {
    width: 45%;
    float: left;
}
.account-info .half-column:first-of-type {
    margin-right: 10%;
}
.account-info .half-column div {
    margin-bottom: 20px;
}
.account-info label {
    font-family: "SF bold";
    font-size: 18px;
    display: inline-block;
    vertical-align: top;
    color: #ffffff;
}
.account-info .inputfield {
    border: 1px solid #ffffff;
    background: none;
    border-radius: 5px;
    width: 65%;
    padding: 0 20px;
    color: #ffffff;
    font-family: "SF text";
    font-size: 14px;
}
.account-info .inputfield option {
    color: var(--main-color);
}
.account-info input {
    height: 40px;
    line-height: 38px;
}
.account-info textarea.inputfield {
    min-height: 90px;
    padding: 10px 20px;
}
.account-info .account-action {
    text-align: center;
    margin-top: 20px;
}
.account-action .cancel,
.account-action input[type="submit"]{
    display: inline-block;
    padding: 10px 20px;
    border-radius: 5px;
    font-family: "SF bold";
    font-size: 16px;
    text-transform: uppercase;
    height: auto;
    line-height: normal;
    color: var(--dark-color);
    border: 1px solid #ffffff;
}
.account-action .cancel {
    color: #ffffff;
}
.account-action .cancel:hover {
    background: #ffffff;
    color: var(--dark-color);
    text-decoration: none;
}
.account-action input[type="submit"] {
    background: #ffffff;
    color: var(--dark-color);
    margin-left: 15px;
}
.account-action input[type="submit"]:focus,
.account-action input[type="submit"]:hover {
    color: #ffffff;
    background: var(--dark-color);
    outline: none;
}
.account-container .nav-tabs a.active, .account-container .nav-tabs a:hover {
    background: var(--main-color);
    color: #ffffff;
}
.account-container .nav-tabs a,
.tabs-container .nav-tabs a {
    font-family: SF semibold;
    font-size: 17px;
    text-transform: uppercase;
    display: table-cell;
    border-radius: 5px 5px 0 0;
    padding: 10px 30px;
    background-color: #fff;
}
.account-container .nav-tabs  a {
    color: var(--dark-color);
}
.account-container .nav-tabs li.active a,
.account-container .nav-tabs li:hover a {
    color: #fff;
}
.date-container .fa {
    position: absolute;
    color: #fff;
    font-size: 20px;
    right: 20px;
    top: 12px;
    z-index: 0;
}
.account-container .tickets {
    overflow: hidden;
}
.account-transactions table {
    width: 100%;
    margin-top: 40px;
    border-collapse: separate;
    border-spacing: 0 5px;
    table-layout: fixed;
}
.account-transactions tbody tr {
    background: #ffffff;
}
.account-transactions tbody tr:hover {
    background: #008b40 !important;
}

.account-transactions thead th {
    text-align: center;
}
.account-transactions tbody tr td {
    font-size: 14px;
    padding: 5px 0;
    border: none;
}
.account-transactions .table thead th {
    border: none;
}
.account-transactions tbody tr td,
.promo-wallet h3 {
    color: var(--main-color);
    text-transform: uppercase;
    font-family: 'SF semibold';
    text-align: center;
}
.account-transactions tbody tr:hover>td {
    color: white !important;
}
.account-transactions thead .fa, .account-transactions thead th .sortable {
    color: #fff;
}
.account-transactions tbody tr td:last-of-type {
    border-radius: 0 10px 10px 0;
}
.account-transactions tbody tr td:first-of-type {
    border-radius: 10px 0px 0px 10px;
}
.balances {
    overflow: hidden;
}
.balances .available-balance {
    width: 50%;
    float: left;
}
.balances .available-balance span {
    font-family: SF semibold;
    color: #fff;
    font-size: 17px;
    display: inline-block;
    margin-right: 30px;
}
.balances .available-balance .balance {
    display: inline-block;
    border: 1px solid #fff;
    padding: 5px 20px;
    font-family: SF text;
    text-transform: uppercase;
    text-align: center;
    color: #fff;
    border-radius: 5px;
    letter-spacing: 1px;
}
.cashout-btn {
    width: 200px;
    padding: 5px 0;
    display: block;
    float: right;
    background: #d5e6f1;
    border-radius: 5px;
    text-transform: uppercase;
    color: red;
    font-family: SF bold;
    font-size: 20px;
    text-align: center;
}
.account-container .record-count.text-muted span {
    color: #ffffff;
}
.tab-pay .react-numeric-input b {
    display: none;
}
.tab-pay .react-numeric-input {
    width: 100%;
}
.tab-pay input[type="number"],
.tab-pay .react-numeric-input input {
    width: 100%;
    border: none !important;
    border-bottom: 2px solid #ffffff !important;
    font-size: 50px;
    color: #ffffff;
    font-family: "SF bold";
    text-align: center;
    margin-bottom: 40px;
    background: none;

}

.tab-pay input::placeholder {
    color: #ffffff;
}
.tab-pay input:-ms-input-placeholder {
    color: #ffffff;
}
.tab-pay input::-ms-input-placeholder {
    color: #ffffff;
}
.tab-pay p {
    color: #ffffff;
    font-family: "SF text";
    font-size: 16px;
    text-align: center;
    margin-bottom: 40px;
}
.account-container .loading-container .fa {
    color: #ffffff;
}
.pay-process {
    display: none;
}
.pay-process.active {
    display: block;
}
.deposit-process {
    overflow: hidden;
    position: relative;
    background: url('assets/images/dashed-line.png');
    background-repeat: repeat-x;
    background-position: center;
}
.deposit-process > span {
    display: inline-block;
    width: 200px;
    background: var(--main-color);
    position: relative;
    color: #ffffff;
    font-family: "SF text";
    font-size: 16px;
    text-transform: uppercase;
    padding: 10px 10px 10px 97px;

}
.deposit-process > span.active {
    text-decoration: none;
    color: #ffffff;
    border: 1px solid #ffffff;
    border-radius: 5px;
}

.deposit-process > span i {
    background-repeat: no-repeat;
    background-size: contain;
    width: 100px;
    height: 45px;
    display: block;
    position: absolute;
    left: 0;
    background-position: center;

}
.deposit-process > span.mobile-info {
    padding-left: 116px;
    float: right;
    width: 240px;
}
.deposit-process > span.deposit-amount {
    margin-left: 90px;
    margin-right: 87px;
}
.deposit-process > span.choose-method i {
    background-image: url('assets/images/choose-method.png');
}
.deposit-process > span.deposit-amount i {
    background-image: url('assets/images/deposit-amount.png');
}
.deposit-text p {
    font-family: "SF bold";
    font-size: 20px;
}
.deposit-text li {
    font-family: "SF semibold";
    font-size: 18px;
    color: #fff;
}
.amount-pay {
    border: 1px solid #cae6f54f;
    border-radius: 5px;
    margin-top: 50px;
    border-top: none;
    background: #ffffff;
}
.container-amount {
    overflow: hidden;
}
.container-amount .fixed-amount {
    width: 50%;
    float: left;
    height: 120px;
    line-height: 120px;
    color: var(--main-color);
    font-family: "SF bold";
    font-size: 22px;
    text-align: center;
    border-radius: 5px;
    background: #ffffff;
    border: 1px solid #cae6f54f;
    text-transform: uppercase;
}
.pay-process .input-amount input {
    margin: 0;
    height: 120px;
    line-height: 120px;
    background: #ffffff;
    text-align: center;
    color: var(--main-color);
    font-family: "SF semibold";
    font-size: 20px;
    border: none;
    width: 100%;
}
.pay-process .input-amount input::placeholder {
    color: var(--dark-color);
}
.pay-process .input-amount input:-ms-input-placeholder {
    color: var(--dark-color);
}
.pay-process .input-amount input::-ms-input-placeholder {
    color: var(--dark-color);
}
.pay-process p {
    margin-top: 20px;
}
.pay-process.ussd-info p {
    margin-top: 40px;
    margin-bottom: 0;
}
.next-btn,
.back-btn:hover,
.back-btn:focus{
    background: var(--main-color);
    color: #ffffff;
    text-decoration: none;
    outline: none;
}
.back-btn,
.next-btn:hover,
.next-btn:focus {
    color: var(--main-color);
    background: #ffffff;
    text-decoration: none;
    outline: none;
}
.next-btn,
.back-btn {
    width: 200px;
    text-align: center;
    font-family: "SF bold";
    font-size: 20px;
    display: inline-block;
    padding: 10px 0;
    border-radius: 5px;
    text-transform: uppercase;
    margin: 30px 10px 0;
    border: 1px solid #ffffff;
}


.mobile-providers .main-btn {
    background: #ffffff;
    color: var(--main-color);
    border-radius: 5px;
    margin: 10px;
    border: 1px solid #ffffff;

}
.mobile-providers .main-btn:hover {
    color: #ffffff;
    background: var(--main-color);
}
.next-btn {
    margin-right: 20px;
}
.next-back {
    text-align: center;
}
.tab-pay.cashout-tab {
    text-align: center;
}
.tab-pay.cashout-tab .back-btn {
    display: inline-block;
    margin: auto 10px;

}
.container-amount.first .fixed-amount:first-of-type,
.container-amount.second .fixed-amount:nth-child(2),
.container-amount.third .fixed-amount:last-of-type,
.container-amount .fixed-amount:hover {
    background: var(--main-color);
    color: #ffffff;
    border-bottom: 1px solid #cae6f54f;
    text-decoration: none;
}
.method-container {
    overflow: hidden;
    text-align: center;
    margin-top: 40px;
}
.pay-process h3 {
    color: #ffffff;
    text-transform: uppercase;
    font-family: "SF semibold";
    font-size: 25px;
    margin-bottom: 20px;
    text-align: center;
}
.method-container .provider-mobile {
    display: inline-block;
    margin-right: 40px;
    vertical-align: middle;
}
.method-container .provider-mobile.tigo {
    width: 75px;
}
.method-container .provider-mobile.vodacom {
    width: 100px;
}
.method-container .provider-mobile.airtel {
    width: 60px;
}
.method-container .provider-mobile.halotel {
    width: 70px;
}
.method-container .provider-mobile:last-child {
    margin-right: 0;
}
.method-container .provider-mobile img {
    width: 100%;
}
.pay-process.mobile .mobilenb-container {
    margin: 50px 0;
    text-align: center;
}
.pay-process.mobile .mobilenb-container input {
    background: white;
    width: 300px;
    border-radius: 5px;
    color: var(--dark-color);
    font-family: "SF UI Medium";
    font-size: 16px;
    border: none;
    height: 50px;
    line-height: 50px;
    margin: auto;
    text-align: left;
    padding: 0 20px;
}
.tab-pay.open {
    display: block;
}
.tab-pay {
    padding: 70px 0 40px;
    display: none;
}
.sub-container {
    width: 90%;
    margin: auto;
}
.choose-action {
    background: #fff;
}
.choose-action .action-pay.active, .choose-action .action-pay:hover {
    color: var(--main-color);
    text-decoration: none;
    position: relative;
}
.choose-action .action-pay {
    display: inline-block;
    width: 50%;
    text-align: center;
    font-family: SF bold;
    color: var(--main-color);
    font-size: 20px;
    padding: 40px 0;
}
.choose-action .action-pay.active:after {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 7.5px 10px;
    border-color: transparent transparent var(--main-color);
    position: absolute;
    content: "";
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
}
.account-transactions .tickets h3 {
    display: inline-block;
    float: left;
    color: #fff;
    font-family: SF bold;
    font-size: 20px;
    text-transform: uppercase;
}
.form-registration .sub-title {
    display: block;
    color: #fff;
    width: 30%;
    margin: auto;
    margin-bottom: 50px;
    margin-top: 20px;
}
.form-registration.verify-form form>div {
    margin: 0 auto 30px!important;
    text-align: center;
    display: block;
    width: 50%;
}
.form-registration .resend-code span {
    color: #ed1c24;
    font-family: SF UI Medium;
    font-size: 14px;
    margin-right: 20px;
    vertical-align: middle;
}
.form-registration .resend-code .resend-btn {
    background: #ed1c24;
    font-family: SF bold;
    font-size: 17px;
    display: inline-block;
    vertical-align: middle;
    border-radius: 5px;
    color: #fff;
    padding: 5px 20px;
    border: 1px solid #ed1c24;
}
.form-registration.verify-form form>div {
    margin: 0 auto 30px!important;
    text-align: center;
    display: block;
    width: 50%;
}

.staticpages-content .loading-container {
    text-align: center;
}
.staticpages-content .loading-container .fa {
    color: #ffffff;
    font-size: 30px;
}
.form-registration form>div.full-width {
    width: 100%;
    text-align: center;
}
.tabs-container .forgot .nav-tabs a {
    background-color: transparent;
}
.form-registration .error {
    padding: 0 20px;
    text-align: center;
    color: #ffffff;
}
.staticpages-content .page-subtitle {
    color: var(--main-color);
    text-transform: uppercase;
    font-size: 17px;
    font-family: "SF UI Medium";
    line-height: 20px;
    text-align: center;
}
.aboutus-text {
    padding: 40px;
    background: var(--dark-color);
    color: #ffffff;
}
.contact-container {
    background: var(--dark-color);
}
.contact-form {
    padding: 40px;
    margin: auto;
}
.contact-form form {
    width: 85%;
    margin: auto;
}
.contact-form .fields-container.half:first-of-type {
    margin-right: 2%;
    width: 48%;
}
.contact-form .fields-container.half {
    width: 50%;
    float: left;
}
.contact-form .fields-container {
    margin-bottom: 30px;
}
.contact-form label {
    color: #fff;
    font-family: "SF semibold";
    font-size: 16px;
    display: inline-block;
    text-transform: uppercase;
    margin-right: 20px;
    vertical-align: middle;
}
.contact-form .fields-container .input {
    background: transparent;
    font-family: "SF text";
    text-transform: uppercase;
    border-radius: 5px;
    font-size: 12px;
    padding: 0 20px;
    color: #fff;
    border: 1px solid #fff;
    display: inline-block;
    width: 100%;
    vertical-align: middle;
}
.contact-form .fields-container input {
    height: 45px;
    line-height: 45px;
}
.contact-form input[type=submit] {
    border: none;
    background: #fff;
    color: var(--dark-color);
    text-transform: uppercase;
    font-family: "SF semibold";
    text-align: center;
    width: 150px;
    margin: auto;
    border-radius: 2px;
    height: 35px;
    line-height: 35px;
    display: block;
}
.contact-details {
    overflow: hidden;
}
.details-contact {
    text-align: center;
    margin-bottom: 10px;
    width: 50%;
    float: left;
    margin-top: 80px;
}
.details-contact .fa {
    color: var(--main-color);
    font-size: 50px;
    display: inline-block;
    margin-right: 10px;
    margin-bottom: 10px;
}
.details-contact h3, .details-contact span {
    text-transform: uppercase;
    color: var(--main-color);
    text-align: center;
}
.details-contact h3 {
    font-size: 18px;
    margin-bottom: 20px;
    font-family: "SF semibold";
}
.details-contact span {
    font-family: "SF text";
    font-size: 15px;
    display: block;
}
.page-text {
    padding: 40px 20px;
    text-align: center;
}
.greenbg {
    background: var(--darkk-color);
    margin: 0 15px;
}
.page-text a, .page-text li, .page-text p, .page-text span {
    font-family: "SF text";
    font-size: 16px;
    color: #fff;
    line-height: 22px;
    text-align:left;
}
.news-slide {
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    height: 40vh;
    position: relative;
    background-position: 50%;
}
.news-slide .news-slide-details {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    bottom: 50px;
    text-align: center;
}
.news-slide .news-slide-details h2 {
    color: #fff;
    text-transform: uppercase;
    width: 80%;
    font-family: SF text;
    font-size: 30px;
    text-align: center;
    margin: 0 auto 30px;
}
.news-slide .main-btn {
    display: inline-block;
}
.news .article-list {
    margin-bottom: 40px;
}
.article-list {
    height: 300px;
}
.article-list .col-12 {
    padding: 0;
    position: relative;
}
.article-list-img img {
    width: 100%;
    height: 300px;
    -o-object-fit: cover;
    object-fit: cover;
    -o-object-position: top;
    object-position: top;
}
.article-list-details.news {
    width: 90%;
    text-align: right;
}
.article-list-details {
    top: 50%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    position: absolute;
    width: 100%;
    text-align: center;
}
.article-list-details.news {
    width: 90%;
    text-align: right;
}
.article-list-details {
    top: 50%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    position: absolute;
    width: 100%;
    text-align: center;
    color: #ffffff;
}
.article-list-details.news {
    width: 90%;
    text-align: right;
}
.article-list-details {
    top: 50%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    position: absolute;
    width: 100%;
    text-align: center;
}
.article-list-details .read-more {
    display: block;
    margin-top: 30px;
    color: #fff;
    font-family: SF text;
    text-transform: uppercase;
}
.article-content h3 {
    color: #fff;
    text-transform: uppercase;
    font-family: "SF bold";
    font-size: 20px;
    margin-bottom: 10px;
}
.article-content p {
    color: #fff;
    font-family: "SF text";
    font-size: 15px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-right: 20px;
}
.back-list {
    font-family: "SF UI Medium";
    font-size: 15px;
    color: var(--dark-color);
    margin-left: 15px;
    display: block;
    margin-bottom: 30px;
    text-transform: uppercase;
}
.back-list:hover {
    color: var(--dark-color);
}
.jackpot-countdown-container .Countdown-col {
    display: inline-block;
    margin: 0 5px;
}
.jackpot-countdown-container .Countdown-col-element {
    display: block;
}
.jackpot-countdown-container .Countdown-col-element strong {
    color: var(--main-color);
    font-family: "SF semibold";
    font-size: 40px;
    line-height: 30px;
}
.jackpot-countdown-container .Countdown-col-element span {
    color: #fff;
    font-family: "SF text";
    font-size: 15px;
    text-transform: uppercase;
}
.jackpot-countdown-container .Countdown-col-element span,
.jackpot-countdown-container .Countdown-col-element strong {
    display: block;
    text-align: center;
}
.news-row .jackpot-countdown-container {
    margin-top: 30px;
    text-align: center;
}
.jackpot-countdown-container>span {
    color: #fff;
    font-size: 14px;
    text-transform: uppercase;
    font-family: SF text;
    display: block;
    text-align: center;
    margin-bottom: 10px;
}
.promotions .article-content {
    padding-top: 30px;
    padding-bottom: 30px;
}
.form-registration .inputfield option {
    background: none;
    color: var(--main-color);
    font-family: SF text;
    font-size: 14px;
}
.account-tabs .loading-container,
.account-tabs .error,
.account-tabs .success {
    text-align: center;
    margin-top: 30px;
}
.account-tabs .error span,
.account-tabs .error i{
    color: #a90c0c;
}
.account-tabs .success span,
.account-tabs .success i {
    color: #ffffff;
}
.form-control.filter-input::-webkit-input-placeholder {
    color: transparent;
}
.form-control.filter-input:-moz-placeholder { /* Firefox 18- */
    color: transparent;
}

.form-control.filter-input::-moz-placeholder {  /* Firefox 19+ */
    color: transparent;
}
.form-control.filter-input:-ms-input-placeholder {
    color: transparent;
}
.filterable-table-container select {
    display: none;
}
.account-transactions .filter-container .close {
    color: #ffffff;
}
.account-transactions span.filter-container::before {
    color: #ffffff;
}
.account-transactions .text-muted {
    display: none;
}
.form-registration.forgot .success i,
.form-registration.forgot .success span {
    color: #fff;
}
.form-registration.forgot .success {
    text-align: center;
    margin-top: 30px;
}

span.filter-container::before {
    content: 'Filtrer';
    color: var(--main-color);
    display: block;
    font-family: "SF text";
    font-size: 14px;
}
.modal-open .modal {
    z-index: 10000000;
}
.modal-footer .btn-secondary {
    background: red !important;
}
.modal-footer .btn-secondary:hover {
    background: red !important;
    opacity: 0.6;
}
.modal-footer .btn-primary {
    background-color: #008b40 !important;
}
.modal-footer .btn-primary:hover {
    background-color: #008b40 !important;
    opacity: 0.6;
    outline: none;
}
.modal-footer .btn {
    width: 100px !important;
    border: none
}
.purchases-table table tbody tr:hover {
    background: #f08c02;
    opacity: 0.8;
}

@media only screen and (max-width: 992px) {
    .deposit-process {
        text-align: center;
    }
    .deposit-process>span {
        display: none;
        text-align: left;
    }
    .deposit-process>span.active {
        display: block;
    }
}
    .non-desktop{
        display: none;
    }

@media only screen and (min-width: 768px) and (max-width: 992px) {
    .container-fluid.content-container {
        width: 90%;
    }
    .form-registration .form-container {
        padding: 30px;
    }
}
@media only screen and (max-width: 767px) {
    .account-basics > div {
        display: block;
    }
    .mojabet-website {
        width: 100%;
        overflow-x: hidden;
    }
    .moja-container {
        padding: 0 15px;
    }
    .only-mobile {
        display: block !important;
    }
    .desktop-only {
        display: none;
    }
    .non-desktop{
        display: block;
    }
    .container-fluid.content-container {
        width: 100%;
    }
    .form-registration .form-title {
        padding: 0 20px;
        font-size: 20px;
    }
    .form-registration .form-container {
        padding: 0 10px;
        text-align: center;
    }
    .form-registration form {
        width: 100%;
        padding: 20px 0;
    }
    .form-registration form>div {
        width: 100%;
        margin-right: 0!important;
    }
    .form-registration label {
        display: block;
        width: 100%;
        margin-bottom: 5px;
    }
    .form-registration .input-container {
        width: 100%;
    }
    .account-container .nav-tabs a {
        width: 50%;
        display: inline-block;
    }
    .account-container .nav-tabs a,
    .tabs-container .nav-tabs a {
        padding: 10px;
    }
    .account-info {
        width: 100%;
    }
    .account-info .half-column {
        width: 100%;
        margin: 0;
    }
    .account-info .half-column:first-of-type {
        margin-right: 10%;
    }
    .account-info .inputfield, .account-info label {
        display: block;
        width: 100%;
    }
    .account-info .inputfield, .account-info label {
        display: block;
        width: 100%;
    }
    .container-fluid.account-container {
        width: 100%;
    }
    .account-container .tab-pane {
        padding: 30px 15px;
    }
    .balances .available-balance {
        width: 45%;
    }
    .balances .cashout-btn {
        width: 130px;
        margin-top: 35px;
    }
    .balances .available-balance span {
        margin-right: 0;
        text-align: center;
        display: block;
    }
    .balances .available-balance .balance {
        padding: 5px;
    }
    .account-transactions .table thead th {
        font-size: 13px;
    }
    .account-transactions tbody tr td {
        font-size: 12px;
    }
    .deposit-process>span.deposit-amount {
        margin: 0 auto;
    }
    .deposit-process>span.mobile-info {
        float: none;
        margin: 0 auto;
    }
    .mobile-providers .main-btn {
        margin: 0 auto 10px;
    }
    .deposit-process>span.deposit-amount {
        margin: 0 auto;
    }
    .form-registration .sub-title {
        width: 90%;
        margin: auto;
    }
    .form-registration .form-container {
        padding: 0 10px;
        text-align: center;
    }
    .form-registration.verify-form form>div {
        WIDTH: 90%;
    }
    .form-registration label {
        display: block;
        width: 100%;
        margin-bottom: 5px;
    }
    .form-registration .input-container {
        width: 100%;
    }
    .form-registration .resend-code span {
        display: block;
        text-align: center;
        margin-bottom: 10px;
        margin-right: 0;
    }
    .tab-pay.cashout-tab .back-btn {
        margin-bottom: 10px;
    }
    .contact-form .fields-container.half,
    .contact-form .fields-container.half:first-of-type {
        width: 100%;
        margin-right: 0;
    }
    .contact-form form {
        width: 100%;
    }
    .contact-form {
        padding: 40px 20px;
    }
    .details-contact {
        width: 100%;
        float: none;
    }
    .article-list {
        height: auto;
    }

    .article-list-details.news {
        width: 100%;
        padding: 20px;
    }
    .article-list-details {
        position: relative;
        -webkit-transform: none;
        -ms-transform: none;
        transform: none;
        top: auto;
        left: auto;
        padding: 10px 0;
    }
    .article-content p,
    .article-content h3 {
        padding: 0 20px;
    }
    .article-list-img {
        padding: 0!important;
    }
}

.game-container {
    min-height: calc(100vh - 350px);
}
@media only screen and (max-width: 992px) {
    .game-container {
        min-height: calc(100vh - 300px);
    }
}

.linkto-label {
    color: #008b40;
    text-decoration: underline;
    cursor: pointer;
    &:hover {
        color: #008b40;
    }
}