@import "../../sass/style.scss";

.header {
  background-color: #efefef;
  width: 100%;
  position: relative;
  z-index: 1000000;
  @media #{$nonDesk} {
    height: 110px;
  }

}

.topHeader {
  @include flex($justify_content: space-between);
  padding: 0 20px;
  @media #{$mobile} {
    padding: 0 5px;
  }
  @media #{$tablet} {
    padding: 0 10px;
  }
}

.logo {
  height: 100px;
  @media #{$nonDesk} {
    height: 85px;
    margin-left: 0px;
    margin-bottom: 0;
  }
}
.logoLonacici {
height: 50px;
left: 160px;
top: 30px;
position: absolute;
@media #{$mobTab} {
  left: 140px;
top: 20px;
}
}

.infoMenu {
  @include flex;
}

.register {
  color: $mainColor;
  font-weight: 500;
  font-family: $mediumfont;
  border: 0;
  display: block;
  font-size: 14px;
  text-transform: none;
  text-decoration: underline;
  @media #{$nonDesk} {
    display: none;
  }
}

.inputField {
  @include field();
  margin-right: 20px;
  @media #{$nonDesk} {
    margin-right: 10px;
    width: 45%;
    font-size: 12px;
  }
}

.btnHeader {
  @include btn();
  position: relative;
  width: 175px;
  @media #{$nonDesk} {
    font-size: 12px;
    line-height: 30px;
    margin: 10px auto;
    width: 200px;
  }
  i {
    top: 10px;
    position: absolute;
    color: #008B40;
    font-size: 20px;
    right: 0;
    @media #{$nonDesk} {
      right: -30px;
    }
  }
}

//.linedropDown {
//  display: none;
//  @media #{$nonDesk} {
//    height: 0.1px;
//    background-color: $mainColor;
//    width: 80%;
//    display: block;
//  }
//}

.dropDownText {
  display: block;
  flex-basis: 100%;
  color: $menuColor;
  font-weight: 500;
  font-family: $mediumfont;
  text-align: right;
  margin: 5px 0;
  font-size: 14px;
  > a {
    color: $mainColor;
    text-decoration: underline;
  }
  @media #{$nonDesk} {
    display: block;
    font-weight: 500;
    font-family: $mediumfont;
    font-size: 10px;
    flex-basis: auto;
    margin-top: 30px;
  }
}

.dropDownSinscrire {
  @media #{$nonDesk} {
    color: $menuColor;
    font-weight: 700;
    font-family: $boldfont;
    font-size: 10px;
    margin-left: 2px;
    cursor: pointer;
  }
}

.dropDownVisible {
  @include flex;
  @media #{$nonDesk} {
    flex-direction: column;
    align-items: center;
    position: absolute;
    width: 100%;
    height: 150px;
    top: 80px;
    left: 0;
    right: 50%;
    transition: 0.6s;
    background-color: $white;
    z-index: 100000000000;
    padding: 0 10px;
  }
}
.form {
  @include flex;
  justify-content: space-around;
  position: relative;
  margin-top: 35px;
  @media #{$mobile} {
    display: block;
  }
}
.dropDownHidden {
  @include flex;
  flex-wrap: wrap;
  justify-content: right;
  @media #{$nonDesk} {
    display: none;
  }
}

.ul_list {
  @include flex($wrap: wrap);
  background-color: $mainColor;
  list-style: none;
  padding: 0 50px;
  margin: 0;
  @media #{$nonDesk} {
    flex-direction: column;
    align-items: flex-start;
    padding: 5px 0 5px 0;
  }
}

.list_word {
  font-size: 13px;
  font-weight: 500;
  font-family: $mediumfont;
  color: $white;
  text-decoration: none;
  display: inline-block;
  padding: 10px 20px;
  text-align: center;
  @media #{$nonDesk} {
    width: 100%;
    padding: 20px;
    display: block;
    text-align: left;
  }
  &:hover,
  &:active {
    // background-color: $secondaryColor;
    color: $secondaryColor;
  }
}

.lineSeperate {
  display: none;
  @media #{$nonDesk} {
    display: block;
    height: 2px;
    width: 100%;
    background-color: white;
    margin: 5px 0 5px 0;
  }
}

.menulist {
  @media #{$nonDesk} {
    position: absolute;
    width: 100%;
    top: 90px;
    transition: left 0.6s;
    background-color: $mainColor;
    &.open {
      left: 0;
    }
    &.close {
      left: -100%;
    }
  }
}

.circleUser {
  display: none;
  @media #{$nonDesk} {
    width: 30px;
    height: 30px;
    border-radius: 100%;
    border: 2px solid $mainColor;
    position: relative;
    display: block;
    margin-right: 50px;
    cursor: pointer;
    text-align: center;
    line-height: 25px;
  }
  i {
    color: $mainColor;
  }
}

.userInnerStyle {
  display: none;
  @media #{$nonDesk} {
    height: 20px;
    @include center_middle_align();
    display: block;
  }
}

.burgermenuContainer {
  display: none;
  @media #{$nonDesk} {
    position: absolute;
    display: flex;
    flex-direction: column;
    right: 20px;
    top: 30px;
    z-index: 100000;
  }
  @media #{$tablet} {
    right: 15px;
  }

  i {
    @media #{$nonDesk} {
      width: 20px;
      height: 4px;
      margin: 2px auto;
      border-radius: 2px;
      transition: all ease 0.5s;
      cursor: pointer;
      background-color: $mainColor;
    }
  }
}

.openbtn:nth-child(1) {
  @media #{$nonDesk} {
    transform: rotate(56deg) translateY(10px) translateX(6px);
  }
}

.openbtn:nth-child(2) {
  opacity: 0;
}

.openbtn:nth-child(3) {
  @media #{$nonDesk} {
    transform: rotate(-59deg) translateY(-7px) translateX(2px);
  }
}

.closebtn:nth-child(1) {
  transform: rotate(0) translateY(0);
}

.closebtn:nth-child(2) {
  opacity: 1;
}

.closebtn:nth-child(3) {
  transform: rotate(0) translateY(0);
}

.userInfoGroup {
  @include flex();
  color: $mainColor;
  margin-right: 15px;
  text-transform: uppercase;
  text-decoration: none;
  letter-spacing: 2px;
  cursor: pointer;
  &:hover,
  &:focus {
    color: #008B40;
    text-decoration: none;
    .userIcon {
      color: #008B40;
    }
  }
  @media #{$nonDesk} {
    margin-right: 10px;
    color: $white;
    &:last-of-type {
      margin-right: 0;
    }
    font-size: 11px;
  }
  &.wallet {
    cursor: default;
  }


  .userIcon {
    box-sizing: border-box;
    margin-right: 5px;
    color: $mainColor;
    border-radius: 50%;
    text-align: center;
    font-size: 20px;
    @media #{$nonDesk} {
      color: $white;
    }
  }
}
.userInfoImg {
  height: 15px;
  line-height: 15px;
  margin-right: 5px;
  font-size: 12px;
  font-weight: 700;
  text-align: center;
  &.dollar {
    width: 20px;
    height: 20px;
    padding: 2px 1px 3px 3px;
    border: 1px solid $mainColor;
    border-radius: 50%;
    @media #{$nonDesk} {
      border-color: $white;
    }
  }
}

.logout {
  font-size: 20px;
}
.userDets {
  @include flex($justify_content: space-between);
  @media #{$nonDesk} {
    position: absolute;
    background: $mainColor;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 5px;
  }
}
.error {
  position: absolute;
  left: 10px;
  bottom: -25px;
  //width: 200px;
  font-size: 11px;
  color: red;
  @media #{$nonDesk} {
    top: calc(100% + 5px);
    text-align: center;
    width: 100%;
  }
}
