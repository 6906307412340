@mixin btn(
  $backgroundColor: $linearGradient,
  $width:300px,
  $height: 40px,
  $fontSize: 15px
) {
  width: $width;
  box-sizing: border-box;
  display: block;
  font-size: $fontSize;
  color: $secondaryColor;
  font-weight: 700;
  font-family: $boldfont;
  text-align: center;
  border-radius: 50px;
  background: $backgroundColor;
  height: $height;
  line-height: 28px;
  text-decoration: none;
  letter-spacing: 3px;
  text-transform: uppercase;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.25);
  padding: 0;
  margin: 0;
  border: 2px solid transparent ;
  &:hover,
  &:focus {
    background: #fff;
    border-color:  #F08C02;
  }
}

@mixin field(
  $backgroundColor: white,
  $hoverBgColor: $backgroundColor,
  $height: 40px,
  $fontSize: 15px
) {
  outline: none;
  border: 3px solid $mainColor;
  border-radius: 50px;
  height: $height;
  border-radius: 50px;
  padding: 13px;
  box-sizing: border-box;
}
