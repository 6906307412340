@import "../../sass/style.scss";

.landingContainer {
    background-color: #efefef;
    padding: 30px 20px 0;
    box-sizing: border-box;

    @media #{$nonDesk} {
        padding: 0 10px;
    }
}

.imagesContainer {
    display: grid;
    column-gap: 10px;
    row-gap: 10px;
    box-sizing: border-box;
    margin-bottom: 10px;

    &.top {
        grid-template-columns: 1fr 1fr;

        @media #{$mobile} {
            grid-template-columns: 50% 47%;
        }
    }

    &.top-middle {
        grid-template-columns: 1fr 1fr;

        @media #{$mobile} {
            grid-template-columns: 50% 47%;
        }
    }

    &.middle {
        grid-template-columns: 24.9% 23.5% 24.5% 24.9%;

        @media #{$mobile} {
            grid-template-columns: 50% 47%;
        }
    }

    &.bottom {
        margin-bottom: 10px;
        grid-template-columns: 24.9% 23.5% 50%;

        @media #{$mobile} {
            grid-template-columns: 50% 47%;
        }
    }
}

.landingBackGround {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    position: relative;
    border-radius: 10px;
    height: 17vh;
}

.sponsored {
    height: 35vh;
}

.routeLaCan {
    background-image: url("../../assets/backgroundimages/EnRoutePourLaCAN.jpg");
    grid-row: span 2;

    @media #{$mobile} {
        grid-column: 1 / 3;
        grid-row: 1;
        height: 20vh;
    }

    @media #{$tablet} {
        background-position: bottom 0px right -140px;
        height: 35vh;
    }

    @media #{$ipad} {
        background-position: bottom 0px right 0px;
    }

    @media #{$minimobile} {
        background-position: bottom 0px right -50px;
    }
}

.routeLaCaf,
.moayeGrattage {
    background-image: url("../../assets/backgroundimages/EnRoutePourLaCaf.jpg");
    grid-row: span 2;
    position: relative;

    @media #{$mobile} {
        grid-column: 1 / 3;
        grid-row: 2;
        height: 25vh;
    }

    @media #{$tablet} {
        background-position: bottom 0px right -140px;
        height: 35vh;
    }

    @media #{$ipad} {
        background-position: bottom 0px right 0px;
    }

    @media #{$minimobile} {
        background-position: bottom 0px right -50px;
    }
}

.moayeGrattage {
    background-image: url("../../assets/backgroundimages/moayeScratch.jpg");
    overflow: hidden;
}

.logoMoaye {
    height: 100px;
    @include middle_align();
    position: absolute;
    right: 15%;
    z-index: 100;
}

.priceMoaye {
    position: absolute;
    top: 10px;
    right: 5%;
    max-height: 67px;

    @media #{$mobile} {
        height: 40px;
    }
}

.moayeEgg {
    max-height: 250px;
    left: -50px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);

    @media #{$tablet} {
        left: -100px;
    }

    @media #{$mobile} {
        display: none;
    }
}

.cafMiddleLogo {
    width: fit-content;
    @include middle_align();
    position: absolute;
    right: 15%;
}

.titlePourCaf,
.titlePourCaf {
    display: block;
    height: 35px;
    margin: 5px auto 0;

    @media #{$mobile} {
        height: 30px;
    }
}

.priceCaf {
    position: absolute;
    top: 10px;
    right: 5%;

    @media #{$mobile} {
        height: 40px;
    }
}

.logoPourCaf {
    @media #{$mobile} {
        height: 55px;
    }
}

.logoPourCan {
    @include center_middle_align();
    top: 30%;
    left: 30%;
    width: 45%;

    @media #{$tablet} {
        width: 40%;
        left: 30%;
    }

    @media #{$mobile} {
        width: 40%;
        left: 30%;
        top: 50%;
    }
}

.tumeDeChance {
    background-image: url("../../assets/backgroundimages/tournetachanceback.jpg");
    grid-column: 1;
    grid-row: 1;
    overflow: hidden;

    @media #{$mobile} {
        grid-column: 1;
        grid-row: 2;
        height: 14vh;
    }

    @media #{$tablet} {
        height: 17vh;
    }
}

.tumeDeChancelogo {
    @include center_middle_align();
    width: 40%;

    @media #{$ipad} {
        width: 40%;
    }

    @media #{$mobile} {
        width: 30%;
    }
}

.BingoAlphabet {
    background-image: url("../../assets//backgroundimages/bingoalphabetNew.jpg");
    grid-column: 2;
    grid-row: 1;

    @media #{$mobile} {
        grid-column: 2;
        grid-row: 2;
        height: 14vh;
    }

    @media #{$tablet} {
        height: 17vh;
    }
}

.sportChance {
    background-image: url("../../assets/backgroundimages/SportDeChance.jpg");
    grid-column: span 2;
    grid-row: 1;

    @media #{$mobile} {
        grid-column: 1;
        grid-row: 3;
        height: 14vh;
    }

    @media #{$tablet} {
        height: 17vh;
    }
}

.bonheurImage {
    background-image: url("../../assets/backgroundimages/bounheur.jpg");
    grid-column: span 2;
    grid-row: 2;

    @media #{$mobile} {
        grid-column: 2;
        grid-row: 3;
        height: 14vh;
    }

    @media #{$tablet} {
        height: 17vh;
    }
}

.fruiteDeChance {
    background-image: url("../../assets/backgroundimages/FruitDeChance.jpg");
    grid-column: 2;
    grid-row: 2;

    @media #{$mobile} {
        grid-column: 1;
        grid-row: 4;
        height: 14vh;
    }

    @media #{$tablet} {
        height: 17vh;
    }
}

.pick3 {
    background-image: url("../../assets/backgroundimages/pick3.jpg");
    grid-column: 2/2;
    grid-row: 1/3;
    height: 35vh;

    @media #{$mobile} {
        grid-column: span 2;
        grid-row: 3;
        height: 25vh;
    }
}

.bingoSafari {
    background-image: url("../../assets/backgroundimages/BingoSafari.jpg");
    grid-column: 1;
    grid-row: 2;

    @media #{$mobile} {
        grid-column: 2;
        grid-row: 4;
        height: 14vh;
    }

    @media #{$tablet} {
        height: 17vh;
    }
}

.bingoSafariLogo {
    @include center_middle_align();
    width: 13%;
    left: 20%;

    @media #{$tablet} {
        width: 15%;
        left: 15%;
    }

    @media #{$ipad} {
        width: 25%;
        left: 15%;
    }

    @media #{$mobile} {
        width: 10%;
        left: 15%;
    }
}

.bingo_Title {
    @include center_middle_align();
    position: absolute;
    text-transform: uppercase;
    color: $white;
    font-size: 20px;
    font-weight: 600;

    @media #{$mobile} {
        font-size: 12px;
    }
}

.millionDouble {
    background-image: url("../../assets/backgroundimages/doublemillionairebg.jpg");
    grid-column: 2 / 1;
    grid-row: 1 / 3;
    overflow: hidden;

    @media #{$mobile} {
        grid-column: 1 / 3;
        grid-row: 1;
        height: 25vh;
    }

    @media #{$tablet} {
        height: 35vh;
    }
}

.monCacao {
    background-image: url("../../assets/backgroundimages/monCacaoBg.jpg");
    grid-column: 2/2;
    grid-row: 1/3;
    height: 35vh;

    @media #{$mobile} {
        grid-column: span 2;
        grid-row: 3;
        height: 25vh;
    }
}

.youpi {
    background-image: url("../../assets/backgroundimages/youpi_bg.jpg");
    grid-column: 2 / 1;
    grid-row: 1 / 3;
    height: 35vh;

    @media #{$mobile} {
        grid-column: 1 / 3;
        grid-row: 1;
        height: 25vh;
    }

    @media #{$tablet} {
        height: 35vh;
    }
}

.doubleMillionairelogo {
    height: 100%;
    @include center_middle_align();
}

.logo {
    @include center_middle_align();
    width: 40%;
}

.bonheurLogo {
    width: 30%;
}

.hotNumbers {
    background-color: #f08c02;
    padding: 5px 0;
    border-radius: 10px;

    &__title {
        color: $white;
        font-size: 24px;
        text-transform: uppercase;
        font-weight: 600;
        display: block;
        margin: 0 0 10px 25px;

        @media #{$mobile} {
            font-size: 14px;
        }
    }

    &__Wrapper {
        @media #{$mobTab} {
            text-align: center;
        }
    }

    &__Number {
        display: inline-block;
        width: 10%;

        @media #{$mobTab} {
            width: 33.3%;
        }
    }

    &__image {
        height: 50px;
        display: block;
        margin: auto;
    }

    &__label {
        display: block;
        font-size: 14px;
        text-align: center;
        color: $white;
    }
}

.monCacaoFlex {
    @include flex(row, nowrap, space-around);
    gap: 10px;
    height: 100%;

    @media #{$mobile} {
        justify-content: center;
    }
}

.bannerImage {
    display: block;

    @media #{$mobile} {
        height: 120px;
    }

    &.disableMobile {
        @media #{$mobile} {
            display: none;
        }
    }
}

.cacaoInfoFlex {
    display: flex;
    height: 100%;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    @media #{$mobTab} {
        display: none;
    }
}