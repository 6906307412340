.monCacao-iframe {
    height: 750px;
    overflow: hidden; 
  }
  
  .bigTtile {
    color: var(--main-color);
    text-transform: uppercase;
    text-align: center;
  }
  
  .sratch-logout {
    padding: 100px 0;
  }
  
  @media only screen and (max-width: 767px) {
    .monCacao-iframe {
      max-height: 700px;
    }
  }
  
  @media screen and (orientation: landscape) {
    .monCacao-iframe {
      height: 830px;
    }
   }
  