.bingoSafari-iframe {
  height: 700px;
  overflow: hidden; 
}

.bigTtile {
  color: var(--main-color);
  text-transform: uppercase;
  text-align: center;
}

.sratch-logout {
  padding: 100px 0;
}

@media only screen and (max-width: 767px) {
  .bingoSafari-iframe {
    max-height: 700px;
  }
}

@media screen and (orientation: landscape) {
  .bingoSafari-iframe {
    height: 750px;
  }
 }
